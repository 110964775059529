'use strict'

import loadScript from "../libs/@elements/load-script";
import {find, setAttribute, removeAttribute, closest, findIn} from "../libs/@elements/dom-utils";

export function init() {

    const apiKey = _config.reCaptchaKey;
    const recaptchaResponse = find('.recaptcha-response');

    if(recaptchaResponse) {
        loadScript('https://www.google.com/recaptcha/api.js?render=' + apiKey).then(() => {
            grecaptcha.ready(function () {

                const recaptchaAction = find('.recaptcha-action');
                const form = closest('form', recaptchaResponse);
                const submitBtn = findIn('button[type="submit"]', form);

                let tempElement = document.createElement("input");
                submitBtn.parentNode.insertBefore( tempElement, submitBtn.nextSibling );

                tempElement.value = submitBtn.value;
                setAttribute('type', "hidden", tempElement);
                tempElement.name = submitBtn.getAttribute('name');

                let tokenPromise = null;

                form.addEventListener('submit', function (e) {
                    e.preventDefault();

                    setAttribute('disabled', true, submitBtn);

                    if(!tokenPromise) {
                        let submitAction = recaptchaAction.value;
                        tokenPromise = grecaptcha.execute(apiKey, {action: submitAction})
                            .then( function(token) {
                                recaptchaResponse.value = token;
                                form.submit();
                            })
                            .catch(function(e){
                                console.log('error', e)
                            })
                            .then(function() {
                                removeAttribute('disabled', submitBtn);
                                tokenPromise = null;
                            });
                    }
                });
            });
        }).catch(e => {
            console.log(e);
        });
    }

}

export function submitAjaxForm(url, formData, callback) {

    let tokenPromise;

    if (_config.reCaptchaV3 !== undefined) {

        const apiKey = _config.reCaptchaKey;

        if (!apiKey) {
            return $.Deferred().reject(new Error('reCaptcha key is not set. Please set _config.reCaptchaKey'));
        }

        loadScript('https://www.google.com/recaptcha/api.js?render=' + apiKey).then(() => {

            grecaptcha.ready(function () {
                if (tokenPromise) {
                    tokenPromise.abort();
                    tokenPromise = null;
                }

                tokenPromise = grecaptcha.execute(apiKey, {action: 'product_rating'})
                    .then(function (token) {
                        // Add captcha token to form data
                        formData.append('g-recaptcha-response', token);
                        callback(url, formData);
                    })
                    .catch(function (e) {
                        console.log('error', e)
                    })
                    .then(function () {
                        tokenPromise = null;
                    });
            });
        });
    } else {
        callback(url, formData);
    }

}
